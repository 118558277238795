export default {
    firebase_error_codes: {
      'auth/email-already-in-use': 'Cette adresse e-mail est déjà utilisée par un autre compte.',
      'auth/operation-not-allowed': 'Il n\'est pas possible de se connecter à ce projet avec un mot de passe.',
      'auth/too-many-requests': 'En raison d\'une activité inhabituellement élevée, nous avons bloqué toutes les demandes provenant de cet appareil. Veuillez réessayer plus tard.',
      'auth/user-not-found': 'Cet utilisateur nous est inconnu.',
      'auth/wrong-password': 'Ce mot de passe est invalide.',
      'auth/user-disabled': 'Ce compte utilisateur a été désactivé par un administrateur.',
      'auth/expired-action-code': 'Le code de réinitialisation du mot de passe a expiré.',
      'auth/invalid-action-code': 'Le code de réinitialisation du mot de passe est invalide. Le code est incorrect ou a déjà été utilisé.',
      'auth/user-token-expired': 'Le jeton de cet utilisateur n\'est plus valide. L\'utilisateur doit se reconnecter.',
      'auth/invalid-user-token': 'Le jeton d\'actualisation est invalide.',
      'auth/invalid-credential': 'Le type d\'autorisation fourni est invalide.',
    },
    navigation: {
      top: {
        bookmarks_text: 'Listes de favoris',
        account_settings_text: 'Paramètres du compte',
        sign_in_text: 'Connexion',
        sign_out_text: 'Déconnexion',
        language_selector: {
          de: 'Allemand',
          en: 'Anglais',
          fr: 'français'
        },
      },
      bottom: {
        home_text: 'Accueil',
        assembly_text: 'Composants',
        tools_text: 'Outils',
        bookmark_text: 'Liste de favoris',
        next_button: {
          main_text: 'Suivant',
          result_count_text: 'Composants',
          result_count_empty_text: 'Aucun composant trouvé',
        },
      },
      view_titles: {
        home_text: '',
        assembly_search: {
          manual_text: 'Filtrage manuel des composants',
          preset_text: 'Recherche guidée des composants',
        },
        reset_password_text: 'Modifier le mot de passe',
        impressum_text: 'Mentions légales',
        data_protection_text: 'Protection des données',
        tools_text: '',
        product_detail_text: 'Détails du produit',
        bookmarks_text: 'Listes de favoris',
        data_privacy_text: 'Protection des données',
        search_results: 'résultats de recherche',
      },
    },
    modal: {
      authentication: {
        form: {
          firstName: {
            placeholder_text: 'Prénom',
            is_empty_text: 'Veuillez entrer votre prénom',
          },
          lastName: {
            placeholder_text: 'Nom',
            is_empty_text: 'Veuillez entrer votre nom',
          },
          company_name: {
            placeholder_text: 'Entreprise',
            is_empty_text: 'Veuillez entrer le nom de votre entreprise',
          },
          street: {
            placeholder_text: 'Rue et numéro',
            is_empty_text: 'Veuillez entrer votre rue et numéro',
          },
          zip: {
            placeholder_text: 'Code postal',
            is_empty_text: 'Veuillez entrer votre code postal',
          },
          city: {
            placeholder_text: 'Ville',
            is_empty_text: 'Veuillez entrer une ville',
          },
          country: {
            placeholder_text: 'Pays',
            is_empty_text: 'Veuillez sélectionner votre pays',
          },
          phone_number: {
            placeholder_text: 'Numéro de téléphone',
            is_empty_text: 'Veuillez entrer votre numéro de téléphone',
          },
          email: {
            placeholder_text: 'Adresse e-mail',
            is_empty_text: 'Veuillez entrer une adresse e-mail valide',
          },
          dataProtect: {
            is_empty_text: 'Veuillez accepter notre politique de confidentialité',
          },
          password: {
            placeholder_text: 'Mot de passe',
            description_text: 'Utilisez 8 caractères ou plus avec un mélange de lettres, de chiffres et de symboles',
            is_invalid_text: 'Veuillez entrer un mot de passe valide',
          },
          error_text: 'Tous les champs n\'ont pas été remplis correctement. Veuillez vérifier vos informations.',
        },
        login: {
          title_text: 'Connexion',
          description_text: 'Connectez-vous pour gérer vos listes de favoris ou vos données.',
          button_text: 'Se connecter',
          no_account_yet_text: 'Pas encore de compte ?',
          register_here_text: 'S\'inscrire ici',
          forgot_password_text: 'Mot de passe oublié ?',
          success: {
            title_text: 'Connexion réussie',
            main_text: 'Bienvenue {userName}',
            description_text: 'Vous pouvez maintenant enregistrer des listes de favoris et des composants.',
          },
        },
        register: {
          title_text: 'Inscription',
          description_text: 'Inscrivez-vous pour enregistrer des composants dans des listes de favoris personnalisées.',
          button_text: 'S\'inscrire',
          data_protection: {
            main_text: 'J\'ai lu et accepté les {dataProtectionLink}.',
            label_text: 'Avis de confidentialité',
            label_link: 'https://www.swisskrono.com/ch-de/datenschutz/#',
          },
          success: {
            title_text: 'Inscription réussie',
            main_text: 'Bienvenue {userName}',
            description_text: 'Vous pouvez maintenant enregistrer des listes de projets et des composants.',
          },
        },
        getUpdates: {
          data_protection: {
            main_text: 'Option de consentement pour être informé par mail ou par courrier des actualités',
            label_text: 'Avis de confidentialité',
            label_link: 'https://www.swisskrono.com/ch-de/datenschutz/#',
          },
        },
        login_register_success: {
          first_part_text: 'Nous vous recommandons d\'ajouter',
          second_part_text: 'Timberplanner en favori / marque-page',
          third_part_text: 'pour un accès plus rapide.',
        },
        reset_password_send_email: {
          title_text: 'Réinitialiser le mot de passe',
          description_text: 'Veuillez entrer l\'adresse e-mail associée à votre compte. Vous recevrez un lien pour changer votre mot de passe.',
          back_to_login_text: 'Retour à la connexion',
          button_text: 'Envoyer',
          success: {
            title_text: 'Réinitialisation du mot de passe',
            main_text: 'Lien envoyé avec succès',
            description_text: 'Veuillez consulter l\'e-mail que nous vous avons envoyé.',
          },
        },
        reset_password_set_password: {
          description_text: 'Veuillez entrer un nouveau mot de passe ici. L\'ancien sera remplacé.',
          button_text: 'Mettre à jour',
        },
      },
    },
    home: {
      welcome_text: 'Bienvenue sur votre',
      main_text: 'Planificateur de composants SWISS KRONO',
      predefined_search: {
        main_text: 'Filtrage des composants selon les exigences légales',
        region_selector_placeholder_text: 'Sélectionner un État',
      },
      manual_search: {
        main_text: 'Filtrage manuel des composants',
        link_text: 'Accéder au filtrage',
      },
      registration: {
        main_text: 'Créer un compte et des listes de favoris',
        link_text: 'S\'inscrire',
      },
    },
    bookmarks: {
      title_text: 'Bonjour, {userName}',
      lists_title_text: 'Listes de favoris',
      general_list_text: 'Liste générale',
      new_list_text: 'Nouvelle liste',
      rename_list_text: 'Renommer la liste',
      delete_list_text: 'Supprimer la liste',
      empty_list: {
        main_text: 'Aucun composant enregistré dans cette liste.',
        description_text: 'Ajouter des composants',
        predefined_search_text: 'Selon les exigences légales',
        manual_search_text: 'Filtrage manuel des composants',
      },
      modal: {
        form: {
          name: {
            placeholder_text: 'Nom',
            is_empty_text: 'Veuillez entrer un nom',
          },
        },
        new_bookmark_list: {
          title_text: 'Créer une nouvelle liste',
          description_text: 'Veuillez entrer le nom de votre nouvelle liste de favoris',
          button_text: 'Enregistrer',
          back_to_save_bookmarked_product_text: 'Retour',
        },
        rename_bookmark_list: {
          title_text: 'Renommer la liste',
          description_text: 'Entrez un nouveau nom',
          button_text: 'Enregistrer'
              },
        delete_bookmark_list: {
          title_text: 'Supprimer la liste',
          description_text: 'Voulez-vous vraiment supprimer « {bookmarkListName} » ? Tous les composants enregistrés dans cette liste seront supprimés.',
          button_text: 'Supprimer'
              },
        save_bookmarked_product: {
          title_text: 'Mémoriser le composant',
          description_text: 'Sélectionnez la liste dans laquelle le composant doit être enregistré.',
          button_text: 'Enregistrer'
        },
      },
    },
    constructions_search: {
      steps: {
        first_step: {
          main_text: 'Données de base',
          helper_text: 'Définissez les détails du projet de construction'
        },
        second_step: {
          main_text: 'Composant',
          helper_text: 'Sélectionnez une catégorie de composant pour déterminer les exigences',
          min_requirements_text: 'Exigences minimales légales',
          airborne_sound_insulation_text: 'Bruit aérien',
          footfall_sound_text: 'Bruit d\'impact',
          fire_resistance_text: 'Protection incendie',
          fire_resistance_class_text: 'Classe'
        },
        third_step: {
          main_text: 'Exigences',
          helper_text: 'Les valeurs de protection contre l\'incendie et le bruit sont automatiquement déterminées',
          filter_form: {
            description_text: 'Exigences minimales et au-delà. Veuillez adapter les valeurs à vos besoins.',
            without_rw_lnw_text: 'Sans',
            rw: {
              main_text: 'Indice d\'affaiblissement acoustique',
              info_description_text: 'Les transmissions latérales doivent également être prises en compte. Veuillez ajuster la valeur si nécessaire. Pour les zones exposées à un bruit extérieur élevé (routier, ferroviaire, aérien), d\'autres exigences doivent être prises en compte.',
              adaptation_text: 'Prendre en compte la valeur d\'adaptation spectrale'
            },
            lnw: {
              main_text: 'Niveau de bruit d\'impact normalisé',
              info_description_text: 'Les transmissions latérales doivent également être prises en compte. Veuillez ajuster la valeur si nécessaire. Pour les zones exposées à un bruit extérieur élevé (routier, ferroviaire, aérien), d\'autres exigences doivent être prises en compte.',
              adaptation_text: 'Prendre en compte la valeur d\'adaptation spectrale'
            },
            fire_resistance_text: 'Classes de résistance au feu',
            additional_specs_text: 'Exigences supplémentaires',
            mechanical_influence_text: 'M (Impact mécanique)',
            cladding_criterion_text: {
              main_text: 'K{subText} 60 (Critère d\'encapsulation)',
              sub_text: '2'
            },
            solid_wood_text: 'SWISS KRONO MAGNUMBOARD OSB',
            tooltips: {
              airborn_insulation_text: 'Les transmissions latérales doivent également être prises en compte. Veuillez ajuster la valeur si nécessaire. Pour les zones exposées à un bruit extérieur élevé (routier, ferroviaire, aérien), d\'autres exigences doivent être prises en compte.',
              airborn_insulation_adaptation_text: 'Les valeurs d\'adaptation spectrale C100-3150, C50-3150 sont des facteurs correctifs pour l\'évaluation des baisses de fréquence sur les courbes de niveau sonore pour les bruits intérieurs.',
              footfall_insulation_text: 'Les transmissions latérales doivent également être prises en compte. Veuillez ajuster la valeur si nécessaire. Pour les zones exposées à un bruit extérieur élevé (routier, ferroviaire, aérien), d\'autres exigences doivent être prises en compte.',
              footfall_insulation_adaptation_text: 'Facteur correctif pour l\'évaluation des composants de bruit d\'impact principalement à basse fréquence. La valeur d\'adaptation spectrale Cl50-2500 prend en compte une gamme de fréquences étendue pour le bruit d\'impact de 50 à 2500 Hz et pour le bruit aérien de 100 à 3150 Hz.',
              fire_resistance_text: 'Les classes de résistance au feu F... répondent aux exigences de la norme DIN 4102-4:2016-05. Les classes de résistance au feu selon EN correspondent à la documentation Lignum « 4.1 Composants en bois – Planchers, murs et revêtements avec résistance au feu ».',
              additional_specs_text: 'Avec MB, seuls les composants avec SWISS KRONO MAGNUMBOARD OSB sont autorisés'
            },
          },
        },
    },
    selectors: {
      helper_text: 'Veuillez sélectionner',
      region_text: 'Région',
      building_type_text: 'Type de bâtiment',
      building_class_text: 'Classe de bâtiment',
      assembly_type_text: 'Composant',
      assembly_type_specific_text: 'Composants spécifiques',
      download_link: 'https://www.swisskrono.de/assets/mime/-UTQ3ZSaYJHjwd0s8XTQ4Iuvr5tPuxfcFdFZT8HhUrKWLBpCz/P01395_Definition-GK_0810_c.pdf'
    },
    modal: {
      confirm_redirection_type: {
        title_text: 'Avertissement',
        description_text: 'Si vous continuez, vous perdrez tous vos paramètres de filtrage. Êtes-vous sûr de vouloir continuer?',
        first_button_text: 'Continuer',
        second_button_text: 'Retour'
      },
      disabled_assembly_type: {
        title_text: 'Sélection impossible',
        description_text: 'Aucune sélection possible pour le composant sélectionné. Soit il n\'est pas disponible dans la classe de bâtiment choisie, soit il n\'est pas autorisé en construction bois. Veuillez sélectionner un autre composant.',
        button_text: 'Ok'
      },
      disabled_assembly_type_specific: {
        title_text: 'Sélection impossible',
        description_text: 'Aucune sélection possible pour le composant sélectionné. Soit il n\'est pas disponible dans la classe de bâtiment choisie, soit il n\'est pas autorisé en construction bois. Veuillez sélectionner un autre composant spécifique.',
        button_text: 'Ok'
        },
      },
    },
    tools: {
      top_left_title_text: 'OUTILS DE CALCUL',
      top_right_title_text: 'vous propose gratuitement divers outils de calcul.',
      first_tool_title_text: 'CALCULATEUR DE VALEUR U',
      first_tool_subtitle_text: '',
      first_tool_main_text: 'Calculez la résistance thermique totale R et le coefficient U ainsi que les valeurs de section et de cadre.',
      first_tool_sub_text: 'Le calcul de la valeur U est effectué selon DIN 4108.',
      second_tool_title_text: 'CALCULATEUR DE CONDENSATION',
      second_tool_subtitle_text: '',
      second_tool_main_text: 'Déterminez facilement si la construction est exempte de condensation en sélectionnant les matériaux utilisés.',
      second_tool_sub_text: 'Calcul de la condensation selon Glaser conformément à DIN 4108-3.',
      third_tool_title_text: 'STATIQUE + PROTECTION INCENDIE',
      third_tool_subtitle_text: '',
      third_tool_main_text: 'Calculez la capacité de charge maximale d\'un mur MAGNUMBOARD en fonction de sa hauteur et de son épaisseur, en tenant compte du taux de combustion et de la résistance au feu.',
      third_tool_sub_text: 'Calcul statique selon DIN EN 1995-1-1 et DIN EN 1995-1-2 (cas d\'incendie).'
    },
    impressum: {
      top_left_title_text: 'MENTIONS LÉGALES',
      top_right_title_text: 'Responsable de ce site web :',
      copyright_open_link_text: 'Ouvrir le copyright'
    },
    data_privacy: {
      top_left_title_text: 'Déclaration de confidentialité (Timberplanner.com)'
    },
    dialog: {
      buttons: {
        save_text: 'ENREGISTRER',
        save_changes_text: 'ENREGISTRER LES MODIFICATIONS',
        change_password_text: 'CHANGER LE MOT DE PASSE',
        update_text: 'METTRE À JOUR',
        delete_account_text: 'SUPPRIMER LE COMPTE',
        continue_text: 'CONTINUER',
        log_in_text: 'SE CONNECTER',
        register_text: 'S\'INSCRIRE',
        cancel_text: 'ANNULER'
      },
    },
    product_details: {
      data_labels: {
        product_text: 'PRODUIT',
        manufacturer_text: 'FABRICANT',
        thickness_text: 'ÉPAISSEUR',
        weight_text: 'POIDS',
        width_text: 'LARGEUR (B)',
        mast_distance_text: 'ENTRAXE (E)'
      },
      back_to_results_text: 'Retour à la liste des résultats',
      save_bookmark_text: 'Mémoriser',
      saved_bookmark_text: 'Mémorisé',
      download_ifc_text: 'Télécharger le fichier IFC',
      specifications_text: 'Texte de soumission GAEB XML',
      specifications_extra: 'Texte de soumission Excel',
      layer_structure_label_text: 'STRUCTURE DES COUCHES',
      first_tab_label_text: 'COMPOSANT DE BASE',
      second_tab_label_text: 'ISOLATION ACOUSTIQUE',
      third_tab_label_text: 'PROTECTION INCENDIE',
      installation_height_label_text: 'Hauteur d\'installation',
      mass_label_text: 'Poids',
      source_label_text: 'Source',
      airborne_sound_label_text: 'Bruit aérien',
      footfall_label_text: 'Bruit d\'impact',
      verified_calculation_label_text: 'Calcul vérifié',
      learn_acustic_label_text: 'En savoir plus sur la détermination des valeurs acoustiques ici :',
      certificate_label_text: 'Certificat',
      fire_protection_class_label_text: 'Classe de protection incendie',
      standard_source_label_text: 'Norme / Source',
      additional_requirements_label_text: 'Exigences supplémentaires / étendues',
      first_tool_title_text: 'CALCULATEUR DE VALEUR U',
      second_tool_title_text: 'CALCULATEUR DE CONDENSATION',
      third_tool_title_text: 'STATIQUE + PROTECTION INCENDIE',
      more_informations_title_text: 'INFORMATIONS SUPPLÉMENTAIRES',
      learn_more_text: 'En savoir plus sur nos produits.',
      information_on_use_more_text: 'INSTRUCTIONS DE TRAITEMENT',
      find_important_informations_text: 'Vous trouverez ici toutes les informations importantes sur le traitement des produits Swiss Krono.',
      export_building_text: 'Exporter le composant pour d\'autres vérifications',
      adaptation_value_text: 'Valeur d\'adaptation spectrale :'
    },
    search_results: {
      more_results_loading_text: 'Chargement de plus de composants',
      results_found_text: 'COMPOSANTS TROUVÉS',
      filter_text: 'FILTRER'
    },
    view_footer: {
      main_title_text: 'Swiss Krono\nPlanificateur de composants',
      contact_text: 'Contact',
      contact_link: 'https://www.swisskrono.com/de-de/kontakt/#/',
      career_text: 'Carrière',
      career_link: 'https://www.swisskrono.com/ch-de/ueber-uns/karriere/#',
      press_text: 'Actualités & Événements',
      press_link: 'https://www.swisskrono.com/de-de/ueber-nous/news-veranstaltungen/#/',
      data_protection_text: 'Protection des données',
      impressum_text: 'Mentions légales',
      need_help_text: 'Besoin d\'aide ?',
      glossary_text: 'Glossaire',
      glossary_link: 'https://www.swisskrono.com/ch-de/glossar/#',
      downloads_link: 'https://www.swisskrono.com/de-de/downloadbereich-holzwerkstoffe/#/'
    },
    cookies: {
      cookies_text: 'Afin d\'optimiser notre site web pour vous et de l\'améliorer en permanence, nous utilisons des cookies. En continuant à utiliser le site web, vous acceptez l\'utilisation des cookies. Vous trouverez plus d\'informations à ce sujet dans notre ',
      cookies_data_protection_text: 'Déclaration de protection des données'
    },
    countries: {
      Afghanistan: "Afghanistan",
      Algeria: "Algérie",
      American_Samoa: "Samoa américaines",
      Andorra: "Andorre",
      Angola: "Angola",
      Anguilla: "Anguilla",
      Antarctica: "Antarctique",
      Antigua_and_Barbuda: "Antigua-et-Barbuda",
      Argentina: "Argentine",
      Armenia: "Arménie",
      Aruba: "Aruba",
      Azerbaijan: "Azerbaïdjan",
      Albania: "Albanie",
      Australia: "Australie",
      Egypt: "Égypte",
      Equatorial_Guinea: "Guinée équatoriale",
      Ethiopia: "Éthiopie",
      Bahrain: "Bahreïn",
      Bangladesh: "Bangladesh",
      Barbados: "Barbade",
      Belgium: "Belgique",
      Belize: "Belize",
      Benin: "Bénin",
      Bermuda: "Bermudes",
      Bhutan: "Bhoutan",
      Bolivia: "Bolivie",
      Bonaire_Sint_Eustatius_and_Saba: "Bonaire, Saint-Eustache et Saba",
      Bosnia_and_Herzegovina: "Bosnie-Herzégovine",
      Botswana: "Botswana",
      Bouvet_Island: "Île Bouvet",
      Brazil: "Brésil",
      British_Virgin_Islands: "Îles Vierges britanniques",
      British_Indian_Ocean_Territory: "Territoire britannique de l'océan Indien",
      Brunei: "Brunei",
      Bulgaria: "Bulgarie",
      Burkina_Faso: "Burkina Faso",
      Burundi: "Burundi",
      Cayman_Islands: "Îles Caïmans",
      Chile: "Chili",
      China: "Chine",
      Cook_Islands: "Îles Cook",
      Costa_Rica: "Costa Rica",
      Curaçao: "Curaçao",
      Germany: "Allemagne",
      The_Bahamas: "Bahamas",
      Dominica: "Dominique",
      Dominican_Republic: "République dominicaine",
      Djibouti: "Djibouti",
      Denmark: "Danemark",
      Ecuador: "Équateur",
      El_Salvador: "El Salvador",
      Cote_dIvoire: "Côte d'Ivoire",
      Eritrea: "Érythrée",
      Estonia: "Estonie",
      Falkland_Islands: "Îles Falkland",
      Fiji: "Fidji",
      Finland: "Finlande",
      France: "France",
      French_Polynesia: "Polynésie française",
      French_Guiana: "Guyane française",
      Faroes: "Îles Féroé",
      Gabon: "Gabon",
      Gambia: "Gambie",
      Georgia: "Géorgie",
      Ghana: "Ghana",
      Gibraltar: "Gibraltar",
      Grenada: "Grenade",
      Greece: "Grèce",
      United_Kingdom: "Royaume-Uni",
      Greenland: "Groenland",
      Guadeloupe: "Guadeloupe",
      Guam: "Guam",
      Guatemala: "Guatemala",
      Guernsey: "Guernesey",
      Guinea: "Guinée",
      Guinea_Bissau: "Guinée-Bissau",
      Guyana: "Guyana",
      Haiti: "Haïti",
      Heard_Island_and_McDonald_Islands: "Îles Heard-et-MacDonald",
      Honduras: "Honduras",
      Hong_Kong_SAR_of_China: "Hong Kong RAS de Chine",
      India: "Inde",
      Indonesia: "Indonésie",
      Isle_of_Man: "Île de Man",
      Iraq: "Irak",
      Iran: "Iran",
      Ireland: "Irlande",
      Iceland: "Islande",
      Israel: "Israël",
      Italy: "Italie",
      Jamaica: "Jamaïque",
      Japan: "Japon",
      Yemen: "Yémen",
      Jersey: "Jersey",
      Jordan: "Jordanie",
      Cambodia: "Cambodge",
      Cameroon: "Cameroun",
      Canada: "Canada",
      Cape_Verde: "Cap-Vert",
      Kazakhstan: "Kazakhstan",
      Qatar: "Qatar",
      Kenya: "Kenya",
      Kyrgyzstan: "Kirghizistan",
      Kiribati: "Kiribati",
      United_States_Minor_Outlying_Islands: "Îles mineures éloignées des États-Unis",
      Cocos_Islands: "Îles Cocos",
      Colombia: "Colombie",
      Comoros: "Comores",
      Congo: "Congo",
      Congo_Brazzaville: "Congo-Brazzaville",
      Croatia: "Croatie",
      Cuba: "Cuba",
      Kuwait: "Koweït",
      Laos: "Laos",
      Lesotho: "Lesotho",
      Latvia: "Lettonie",
      Lebanon: "Liban",
      Liberia: "Libéria",
      Libya: "Libye",
      Liechtenstein: "Liechtenstein",
      Lithuania: "Lituanie",
      Luxembourg: "Luxembourg",
      Macao_SAR_of_China: "Macao RAS de Chine",
      Madagascar: "Madagascar",
      Malawi: "Malawi",
      Malaysia: "Malaisie",
      Maldives: "Maldives",
      Mali: "Mali",
      Malta: "Malte",
      Morocco: "Maroc",
      Marshall_Islands: "Îles Marshall",
      Martinique: "Martinique",
      Mauritania: "Mauritanie",
      Mauritius: "Maurice",
      Mayotte: "Mayotte",
      Macedonia: "Macédoine",
      Mexico: "Mexique",
      Micronesia: "Micronésie",
      Moldova: "Moldavie",
      Monaco: "Monaco",
      Mongolia: "Mongolie",
      Montenegro: "Monténégro",
      Montserrat: "Montserrat",
      Mozambique: "Mozambique",
      Myanmar: "Myanmar",
      Namibia: "Namibie",
      Nauru: "Nauru",
      Nepal: "Népal",
      New_Caledonia: "Nouvelle-Calédonie",
      New_Zealand: "Nouvelle-Zélande",
      Nicaragua: "Nicaragua",
      Netherlands: "Pays-Bas",
      Niger: "Niger",
      Nigeria: "Nigéria",
      Niue: "Niue",
      North_Korea: "Corée du Nord",
      Norfolk_Island: "Île Norfolk",
      Norway: "Norvège",
      Northern_Marianas: "Îles Mariannes du Nord",
      Oman: "Oman",
      Austria: "Autriche",
      Pakistan: "Pakistan",
      Palau: "Palaos",
      Palestine: "Palestine",
      Panama: "Panama",
      Papua_New_Guinea: "Papouasie-Nouvelle-Guinée",
      Paraguay: "Paraguay",
      Peru: "Pérou",
      Philippines: "Philippines",
      Pitcairn_Islands: "Îles Pitcairn",
      Poland: "Pologne",
      Portugal: "Portugal",
      Puerto_Rico: "Porto Rico",
      Reunion: "La Réunion",
      Rwanda: "Rwanda",
      Romania: "Roumanie",
      Russia: "Russie",
      Saint_Pierre_and_Miquelon: "Saint-Pierre-et-Miquelon",
      Saint_Barthélemy: "Saint-Barthélemy",
      Saint_Martin: "Saint-Martin",
      Solomon_Islands: "Îles Salomon",
      Saint_Lucia: "Sainte-Lucie",
      Zambia: "Zambie",
      Samoa: "Samoa",
      San_Marino: "Saint-Marin",
      Saudi_Arabia: "Arabie saoudite",
      Sweden: "Suède",
      Switzerland: "Suisse",
      Senegal: "Sénégal",
      Serbia: "Serbie",
      Seychelles: "Seychelles",
      Sierra_Leone: "Sierra Leone",
      Zimbabwe: "Zimbabwe",
      Singapore: "Singapour",
      Sint_Maarten: "Saint-Martin (partie néerlandaise)",
      Slovakia: "Slovaquie",
      Slovenia: "Slovénie",
      Somalia: "Somalie",
      South_Africa: "Afrique du Sud",
      Spain: "Espagne",
      Sri_Lanka: "Sri Lanka",
      Saint_Helena_Ascension_and_Tristan_da_Cunha: "Sainte-Hélène, Ascension et Tristan da Cunha",
      Saint_Kitts_and_Nevis: "Saint-Christophe-et-Niévès",
      Saint_Vincent_and_the_Grenadines: "Saint-Vincent-et-les-Grenadines",
      Sudan: "Soudan",
      Suriname: "Suriname",
      Svalbard: "Svalbard et Jan Mayen",
      Swaziland: "Eswatini",
      Syria: "Syrie",
      Sao_Tome_e_Principe: "Sao Tomé-et-Principe",
      South_Georgia_and_the_South_Sandwich_Islands: "Géorgie du Sud-et-les Îles Sandwich du Sud",
      French_Southern_Territories: "Terres australes françaises",
      South_Korea: "Corée du Sud",
      South_Sudan: "Soudan du Sud",
      Tajikistan: "Tadjikistan",
      Taiwan: "Taïwan",
      Tanzania: "Tanzanie",
      Thailand: "Thaïlande",
      Togo: "Togo",
      Timor_Leste: "Timor oriental",
      Tokelau: "Tokelau",
      Tonga: "Tonga",
      Trinidad_and_Tobago: "Trinité-et-Tobago",
      Chad: "Tchad",
      Czech_Republic: "République tchèque",
      Tunisia: "Tunisie",
      Turkmenistan: "Turkménistan",
      Turks_and_Caicos_Islands: "Îles Turques-et-Caïques",
      Tuvalu: "Tuvalu",
      Turkey: "Turquie",
      US_Virgin_Islands: "Îles Vierges des États-Unis",
      Uganda: "Ouganda",
      Ukraine: "Ukraine",
      Hungary: "Hongrie",
      Uruguay: "Uruguay",
      Uzbekistan: "Ouzbékistan",
      Vanuatu: "Vanuatu",
      Vatican_City: "Cité du Vatican",
      Venezuela: "Venezuela",
      United_Arab_Emirates: "Émirats arabes unis",
      United_States: "États-Unis",
      Vietnam: "Vietnam",
      Wallis_and_Futuna: "Wallis-et-Futuna",
      Christmas_Island: "Île Christmas",
      Belarus: "Biélorussie",
      Western_Sahara: "Sahara occidental",
      Central_African_Republic: "République centrafricaine",
      Cyprus: "Chypre",
    },
  };